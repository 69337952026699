.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 45px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    width: 225px;

    & > span {
      white-space: nowrap;
    }

    & > label {
      height: 3px;
      border-radius: 4px;
      width: 100%;
      margin: 0 3px;
    }
  }

  & > .completed {
    & > label {
      background-color: #13E11C;
    }
  }

  & > .partial {
    & > label {
      background-color: #E19B13;
    }
  }

  & > .uncompleted {
    & > label {
      background-color: #E11313;
    }
  }
}

@media screen and (max-width: 700px) {
  .progress-bar {
    display: flex;
    flex-direction: column;
    margin: 30px 0 40px;

    & > div {
      width: 100%;
    }
  }
}
