.section-wrapper {
  position: relative;
  padding-bottom: 50px;
}

.section-icon {
  position: absolute;
  top: 0;
  left: -55px;
  height: 44px;
}

.section-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 20px;
  padding-left: 6px;
}

.section-item-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -10px;
  margin-bottom: 27px;

  & > .border {
    width: 4px;
    margin-right: 10px;
    border-radius: 4px;
  }

  & > .border-red {
    background-color: #E11313;
  }

  & > .border-yellow {
    background-color: #E19B13;
  }

  & > .border-green {
    background-color: #13E11C;
  }
}

.section-item-content {
  width: 100%;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 2px 4px;

  // &:hover {
  //   border-color: #153a81;
  // }
}

.section-item-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 10px;
}

.section-item-description {
  position: relative;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 7px;
}

.section-item-description-mark {
  position: absolute;
  left: -57px;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 7px;

  &.short {
    left: -43px;
  }
}

.section-item {
  position: relative;

  & > .mark {
    position: absolute;
    left: -42px;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 5px;
  }

  & > .paragraph {
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 5px;
  }
}

.section-item-result-block {
  position: relative;
}

.section-item-result-icon {
  position: absolute;
  top: 50%;
  left: -47px;
  transform: translateY(-50%);
}

.section-item-result-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 3px;
}

.section-item-result-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 10px;
}

.anchor {
  position: absolute;
  // display: none;
  top: -125px;
}

@media screen and (max-width: 800px) {
  .section-wrapper {
    width: calc(100% - 21px);
    margin-left: 40px;
  }
}
