.header {
  background: #EEEEEE;
  height: 120px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;

  & .nav-logo {
    display: none;
  }

  & .welcome-text {
    font-family: PT Sans Caption;
    word-wrap: break-word;
  }

  & > div {
    display: flex;
    width: 1200px;
    height: 100%;
    margin: auto;

    & > span {
      width: calc(100% - 380px);
      margin: auto 0;
      margin-left: 190px;
      color: #153A81;
      font-size: 21px;
      font-weight: bold;
      line-height: 31px
    }

    & > nav {
      width: calc(100% - 360px);
      max-width: 700px;
      margin: auto;
      display: flex;
    }
  }

  & > .logo {
    cursor: pointer;

    & > img {
      position: absolute;
      width: 68px;
      height: 80px;
      left: 100px;
      top: 18px;
    }
  }

}

.navlink {
  display: flex;
  width: 100%;
  transition: .5s;
  cursor: pointer;
  margin: 0 5px;
  border: solid 2px #EEEEEE;
  border-radius: 4px;

  & .icon {
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-origin: border-box;
    background-size: cover;
    background-color: #EEEEEE;
    z-index: -1;

    &.icon-1 {
      background-image: url(/client/assets/icon4.png);
    }

    &.icon-2 {
      background-image: url(/client/assets/icon5.png);
    }

    &.icon-3 {
      background-image: url(/client/assets/icon3.png);
    }

    &.icon-4 {
      background-image: url(/client/assets/icon6.png);
    }

    &.icon-5 {
      background-image: url(/client/assets/icon2.png);
    }

    &.icon-6 {
      background-image: url(/client/assets/icon1.png);
    }
  }

  & > span {
    font-size: 18px;
    line-height: 23px;
    margin: auto;
  }

  &:hover {
    border: solid 2px #153A81;
    color: #153A81;
    padding: 0 15px;

    & > span {
      display: block !important;
    }

    & > .icon-1 {
      width: 110px;
      background-image: url(/client/assets/icon4_blue.png);
    }

    & > .icon-2 {
      width: 98px;
      background-image: url(/client/assets/icon5_blue.png);
    }

    & > .icon-3 {
      width: 136px;
      background-image: url(/client/assets/icon3_blue.png);
    }

    & > .icon-4 {
      width: 106px;
      background-image: url(/client/assets/icon6_blue.png);
    }

    & > .icon-5 {
      width: 80px;
      background-image: url(/client/assets/icon2_blue.png);
    }

    & > .icon-6 {
      width: 123px;
      background-image: url(/client/assets/icon1_blue.png);
    }
  }

  &:active {
    transition: .1s;
    border: solid 2px #153A81;
    -webkit-box-shadow: inset 0px 0px 20px -4px #153A81;
    -moz-box-shadow: inset 0px 0px 20px -4px #153A81;
    box-shadow: inset 0px 0px 20px -4px #153A81;
  }

  &.active {
    color: #153A81;
    border: solid 2px #153A81;
    padding: 0 15px;
    font-weight: bold;

    & > .icon-1 {
      width: 110px;
      background-image: url(/client/assets/icon4_blue.png);
    }

    & > .icon-2 {
      width: 98px;
      background-image: url(/client/assets/icon5_blue.png);
    }

    & > .icon-3 {
      width: 136px;
      background-image: url(/client/assets/icon3_blue.png);
    }

    & > .icon-4 {
      width: 106px;
      background-image: url(/client/assets/icon6_blue.png);
    }

    & > .icon-5 {
      width: 80px;
      background-image: url(/client/assets/icon2_blue.png);
    }

    & > .icon-6 {
      width: 123px;
      background-image: url(/client/assets/icon1_blue.png);
    }
  }

  &:not(.active) {
    & > span {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    & > div {
      display: flex;
      width: 100%;
      height: 100%;
      margin: auto;

      // & > span {
      //   margin: auto 0;
      //   color: #153A81;
      //   font-size: 21px;
      //   font-weight: bold;
      //   line-height: 31px
      // }

      // & > nav {
      //   margin: auto;
      //   display: flex;
      // }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    min-height: 120px;
    height: auto;

    & .logo {
      display: none;
    }

    & .nav-logo {
      display: block;
      margin-right: 10px;
    }

    & > div {
      min-height: 120px;

      & > nav {
        width: 100%;
        max-width: 100%;
        margin: auto 20px;
      }

      & > span {
        width: 100%;
        margin: auto;
      }
    }
  }

  .navlink {
    border: none;

    &:hover {
      border: none;
      padding: 0;

      & > .icon-1 {
        width: 80px;
      }

      & > .icon-2 {
        width: 80px;
      }

      & > .icon-3 {
        width: 80px;
      }

      & > .icon-4 {
        width: 80px;
      }

      & > .icon-5 {
        width: 80px;
      }

      & > .icon-6 {
        width: 80px;
      }

      & > span {
        display: none !important;
      }
    }

    &:active {
      border: none;

      & > span {
        display: none;
      }
    }

    &.active {
      border: none;
      padding: 0;

      & > .icon-1 {
        width: 80px;
      }

      & > .icon-2 {
        width: 80px;
      }

      & > .icon-3 {
        width: 80px;
      }

      & > .icon-4 {
        width: 80px;
      }

      & > .icon-5 {
        width: 80px;
      }

      & > .icon-6 {
        width: 80px;
      }

      & > span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header {
    min-height: auto;
    height: auto;

    & .logo {
      display: none;
    }

    & .nav-logo {
      display: block;
      margin-right: 10px;

      & > img {
        // width: calc(100vw / 7);
        height: calc(100vw / 7);
      }
    }

    & > div {
      min-height: auto;

      & > nav {
        margin: auto;
      }
    }
  }

  .navlink {
    border: none;
    width: calc(100vw / 7);
    margin: 0;

    .icon {
      width: calc(100vw / 7);
      height: calc(100vw / 7);
    }

    &:hover,
    &:active,
    &.active {
      border: none;
      padding: 0;

      & > .icon-1,
      & > .icon-2,
      & > .icon-3,
      & > .icon-4,
      & > .icon-5,
      & > .icon-6 {
        width: calc(100vw / 7);
        height: calc(100vw / 7);
      }

      & > span {
        display: none;
      }
    }
  }
}
