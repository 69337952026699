* {
  margin: 0;
  padding: 0;
  font-family: PT Sans;
}

.app {
  width: 100%;
  height: 100%;
  min-height: 200vh;
  background: #fff;
}

.main {
  width: 700px;
  margin: auto;
  margin-top: 120px;

  & > .item-0 {
    padding-top: 30px;

    & > p {
      font-size: 18px;
      line-height: 23px;
      margin: 20px 0;

      & > a {
        text-decoration: none;
        color: #153A81;

        &:visited {
          color: #551A8B;
        }
      }
    }
  }
}

.text-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 15px;
}

.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > .category-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 225px;
    height: 85px;
    background: #EEEEEE;
    border: 2px solid #eeeeee;
    border-radius: 4px;
    margin: 8px 0;
    cursor: pointer;
    padding: 5px;

    & .icon {
      height: 65px;
      width: 65px;
      background-repeat: no-repeat;
      background-clip: border-box;
      background-origin: border-box;
      background-size: cover;
      background-color: #EEEEEE;

      &.icon-1 {
        background-image: url(/client/assets/icon4.png);
      }

      &.icon-2 {
        background-image: url(/client/assets/icon5.png);
      }

      &.icon-3 {
        background-image: url(/client/assets/icon3.png);
      }

      &.icon-4 {
        background-image: url(/client/assets/icon6.png);
      }

      &.icon-5 {
        background-image: url(/client/assets/icon2.png);
      }

      &.icon-6 {
        background-image: url(/client/assets/icon1.png);
      }
    }

    &:hover {
      border: solid 2px #153A81;
      color: #153A81;

      & > .icon-1 {
        // width: 110px;
        background-image: url(/client/assets/icon4_blue.png);
      }

      & > .icon-2 {
        // width: 98px;
        background-image: url(/client/assets/icon5_blue.png);
      }

      & > .icon-3 {
        // width: 138px;
        background-image: url(/client/assets/icon3_blue.png);
      }

      & > .icon-4 {
        // width: 108px;
        background-image: url(/client/assets/icon6_blue.png);
      }

      & > .icon-5 {
        // width: 80px;
        background-image: url(/client/assets/icon2_blue.png);
      }

      & > .icon-6 {
        // width: 125px;
        background-image: url(/client/assets/icon1_blue.png);
      }
    }

    &:active {
      color: #153A81;
      border: solid 2px #153A81;
      font-weight: bold;

      & > .icon-1 {
        // width: 110px;
        background-image: url(/client/assets/icon4_blue.png);
      }

      & > .icon-2 {
        // width: 98px;
        background-image: url(/client/assets/icon5_blue.png);
      }

      & > .icon-3 {
        // width: 138px;
        background-image: url(/client/assets/icon3_blue.png);
      }

      & > .icon-4 {
        // width: 108px;
        background-image: url(/client/assets/icon6_blue.png);
      }

      & > .icon-5 {
        // width: 80px;
        background-image: url(/client/assets/icon2_blue.png);
      }

      & > .icon-6 {
        // width: 125px;
        background-image: url(/client/assets/icon1_blue.png);
      }
    }

    & > span {
      margin: auto;
      font-size: 15px;
      line-height: 23px;
      max-width: 144px;
    }

    & > img {
      height: 60px;
      flex-basis: 20%;
      padding: 0 6px 0 0;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 20px;

  & .protivpytok {
    display: flex;
    font-style: italic;

    a {
      color: #000;
      border: none;
      outline: none;

      &:hover, &:active {
        color: #153a81;
      }
    }

    &-logo {
      // display: block;
      height: 100%;
      & > img {
        height: 40px;
      }
    }

    &-text {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  & .kandk {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-style: italic;

    a {
      color: #000;
      border: none;
      outline: none;

      &:hover, &:active {
        color: #153a81;
      }
    }
  }
}

.scroll-to-top-btn {
  cursor: pointer;
  display: none;
  position: fixed;
  bottom: 100px;
  left: 50px;

  &.active {
    display: block;
  }
}

.go-home-btn {
  cursor: pointer;
  outline: none;
  display: none;
  position: fixed;
  bottom: 50px;
  left: 50px;

  &.active {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .main {
    // width: 100%;
    width: calc(100% - 42px);
  }

  .categories {
    display: flex;
    flex-direction: column;

    & > .category-card {
      width: 100%;
      height: 85px;
    }
  }

  .scroll-to-top-btn,
  .go-home-btn {
    left: 10px;
    background: #fff;

    & > img {
      width: 25px;
      height: 25px;
    }
  }

  .scroll-to-top-btn {
    bottom: 45px;
  }

  .go-home-btn {
    bottom: 15px;
  }
}
